<template>
  <div>
    <div class="title-tabs">
      <b-row calss="">
        <b-col md="6" class="text-left d-flex">
          <b-form-checkbox class="" id="Template" name="Template">
          </b-form-checkbox>
          <div>Template</div></b-col
        >
        <b-col md="6" class="justify-content-end d-flex">
          <div class="mr-1 text-link cursor-pointer">Save</div>
          <div>|</div>
          <div class="ml-1 text-link cursor-pointer">Cancel</div>
        </b-col>
      </b-row>
    </div>
    <div class="p-3">
      <b-row>
        <b-col md="6">
          <div>
            <InputSelect
              title="Template"
              :options="templateOptions"
              valueField="value"
              textField="text"
              v-model="form.template"
              placeholder="Template"
            >
            </InputSelect>
          </div>
          <div>
            <UploadFile
              v-model="form.image_url"
              :value="form.image_url"
              textFloat="Image"
              placeholder="Please Select a File To Upload."
              format="image"
              name="thumbnail"
              accept="image/jpeg, image/png"
              text="*Please upload only file .png, .jpg ratio more than 1:45 and size less than 1 MB."
              v-on:onFileChange="onImageChange"
              isRequired
              :fileName="form.image_url"
              :maxSize="1000000"
            />
          </div>
        </b-col>
        <b-col md="6"> </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import UploadFile from "@/components/inputs/UploadFile";

export default {
  components: {
    InputText,
    InputSelect,
    UploadFile,
  },
  props: {
    form: {
      required: "true",
      type: Object,
    },
  },

  data() {
    return {
      showPreview: this.form.image_url,
      isLoadingImage: false,
      templateOptions: [{ text: "3*2", value: "3*2" }],
    };
  },
  validations() {
    return {
      form: {},
    };
  },
  async created() {},
  methods: {
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        let img = new Image();

        img.src = reader.result;

        img.onload = async () => {
          const ratio = img.width / img.height;
          console.log(ratio);
          if (ratio < 1.45) {
            this.warningAlert(
              "Please upload an image with a ratio equal to or more than 1:45 !"
            );
            this.isLoadingImage = false;
            this.isDisable = false;
          } else if (img.height < 250) {
            this.warningAlert(
              "Please upload an image with a height of at least 250 pixels."
            );
            this.isLoadingImage = false;
            this.isDisable = false;
          } else if (img.width < 800 || img.width > 2500) {
            this.warningAlert(
              "Please upload an image with a width equal to 800 to 2500 pixels."
            );
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.images = await this.saveImagetoDb(reader.result);
            this.isLoadingImage = false;
            this.isDisable = false;
            this.form.image_url = this.images;
            this.showPreview = this.form.image_url;
          }
        };
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
        is_video: false,
        type: 0,
      };
      await this.$store.dispatch("uploadProductImage", form);
      var data = await this.$store.state.product.stateUploadProductImage;

      if (data.result == 1) {
        return data.detail;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
</style>
